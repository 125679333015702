import styled from "styled-components";

export const HeaderH1 = styled.h1`
  text-transform: uppercase;
  text-align: center;
  font-size: 60px;
  line-height: 60px;
  letter-spacing: 4px;
  margin: 0;
  transition: 0.5s;
  transform: rotate(0);
  text-shadow: 0 0 10px var(--blue);
  user-select: none;
  &:hover {
    transform: rotate(-2deg);
  }
  & a {
    color: var(--white);
    transition: 1s;
    &:hover {
      text-decoration: none;
      color: var(--red);
    }
  }
  @media screen and (max-width: 768px) {
    margin: var(--insidePadding);
  }
`;

export const MenuDiv = styled.ul`
  margin: 0;
  padding: 0;
  margin-bottom: var(--outsidePadding);
  font-size: 15px;
  font-family: var(--uiFont);
  display: flex;
  justify-content: space-between;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 1px;
  user-select: none;
  & li {
    white-space: nowrap;
    display: inline-block;
    user-select: none;
    border-bottom: 2px solid transparent;
    transition: 0.5s;
    transform: rotate(0);
    &:hover {
      transform: rotate(-2deg);
    }
    &.on {
      border-bottom-color: var(--red);
    }
    & + li {
      margin-left: var(--insidePadding);
    }
    & a {
      color: var(--white);
      transition: 0.5s;
      &:hover {
        color: var(--red);
        text-shadow: 0 0 5px rgba(255, 255, 255, 0.5);
        text-decoration: none;
        font-weight: bold;
      }
    }
  }
  @media screen and (max-width: 1023px) {
    font-size: 13px;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: var(--insidePadding);
    & li {
      /* margin: 0 var(--insidePadding); */
      &.on {
        border-bottom-color: transparent;
        & a {
          color: var(--red);
          text-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
        }
      }
      & + li {
        margin-left: var(--outsidePadding);
      }
    }
  }
  @media screen and (max-width: 768px) {
    padding: var(--insidePadding);
  }
`;

export const Tagline = styled.h2`
  user-select: none;
  margin: 0;
  text-align: center;
  color: var(--white);
  margin-top: var(--insidePadding);
  margin-bottom: var(--outsidePadding);
  font-weight: normal;
`;
