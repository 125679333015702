/**
 * SEO component that queries for data with
 *  Gatsby's useStaticQuery React hook
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";

function SEO({ description, title, bookData }) {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );
  const metaTitle = title || site.siteMetadata.title;
  const metaDescription = description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang: "en",
      }}
    >
      <title>{metaTitle}</title>
      <meta name="description" content={metaDescription} />
      <meta itemprop="description" content={metaDescription} />
      <link rel="canonical" href="https://ringondeli.com" />
      <meta name="robots" content="index,follow" />
      <meta name="googlebot" content="index,follow" />
      <meta itemprop="name" content={`Eric Giroux: Ring On Deli`} />
      <meta
        itemprop="image"
        content="https://www.ringondeli.com/images/ringondelisquare.png"
      />
      <meta property="og:site_name" content={`Eric Giroux: Ring On Deli`} />
      <meta property="og:title" content={metaTitle} />
      <meta property="og:locale" content="en_us" />
      <meta property="og:url" content="https://www.ringondeli.com" />
      <meta property="og:description" content={metaDescription} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
      <meta
        property="og:image"
        content="https://www.ringondeli.com/images/ringondelisquare.png"
      />
      <meta property="og:image:alt" content="RING ON DELI by Eric Giroux" />
      <meta property="og:image:width" content="512" />
      <meta property="og:image:height" content="512" />
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={site.siteMetadata.author} />
      <meta name="twitter:site" content="https://www.ringondeli.com" />
      <meta name="twitter:title" content={"Ring On Deli"} />
      <meta
        name="twitter:image"
        content="https://www.ringondeli.com/images/ringondelisquare.png"
      />
      <meta name="twitter:image:alt" content="RING ON DELI by Eric Giroux" />
      <meta name="twitter:description" content={metaDescription} />
      {bookData ? (
        <script type="application/ld+json" data-rh="true">
          {`{
            "@context": "http://schema.org",
            "@type": "Book",
            name: "Ring On Deli",
            copyrightYear: 2020,
            inLanguage: "en-US",
            genre: "fiction",
            publisher: { "@type": "Organization", name: "New Salem Books" },
            thumbnailUrl: "https://www.ringondeli.com/images/ringondeli.png",
            author: { "@type": "Person", name: "Eric Giroux" },
            isbn: "978-1-7342240-0-9",
            numberOfPages: 288,
            bookEdition: "First edition",
            abstract:
              "<p>Brothers Ray and Patrick Markham live in Pennacook, Massachusetts, a despairing former mill town plagued by feral boars. It’s the type of place where streets are named for scoundrel governors and lesser Monopoly properties, where even Dr. Chong, the high-school principal, can’t bear to mingle with the locals in her free time.</p><p>Ray serves as Patrick’s legal guardian. He spends his days on the Bounty Bag deli line, making a mean meatball sandwich, critiquing Muscles Carbonara’s obscenely suggestive deal flyers, and studiously ducking any thought of his future. But Ray’s tick-like comfort in the static here and now is wildly disrupted when Patrick runs away and a greedy board of directors fires Angie Martini, Bounty Bag’s great-hearted CEO. But Ray’s tick-like comfort in the static here and now is wildly disrupted when Patrick runs away and a greedy board of directors fires Angie Martini, Bounty Bag’s great-hearted CEO, turning Bounty Bag upside down with worker protests.</p><p>Dr. Chong has troubles of her own. She’s on a longshot campaign for a tax-cap override to fund a less-carcinogenic building for Andrew Johnson Memorial High School. But as Election Day nears, the meltdown at Bounty Bag threatens to gut her already shoestring tax base.</p><p>Patrick, meanwhile, has fallen into a scam targeting Ray’s own deli. Things look bleak—until he lucks into the classroom of the dying Mr. Grant, whose demanding history class gets him thinking more deeply, about Pennacook, Bounty Bag, and his own past and future.</p>\n",
          }`}
        </script>
      ) : null}
    </Helmet>
  );
}

SEO.defaultProps = {
  description: ``,
};

SEO.propTypes = {
  description: PropTypes.string,
  title: PropTypes.string.isRequired,
};

export default SEO;
