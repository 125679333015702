import React from "react";
import { graphql, useStaticQuery, Link } from "gatsby";
// import Config from "./../../config.js";
import { MenuDiv } from "./../styledcomponents/headerstyles";

const Menu = () => {
  const pageList = [
    ...useStaticQuery(graphql`
      query pageListQuery {
        allMarkdownRemark {
          edges {
            node {
              frontmatter {
                title
                shorttitle
                type
                slug
                order
              }
            }
          }
        }
      }
    `).allMarkdownRemark.edges.map((x) => x.node.frontmatter),
    {
      title: "Free Samples!",
      shorttitle: "Free Samples!",
      slug: "freesamples",
      order: 4,
      type: "page",
    },
    {
      slug: "updates",
      title: "Updates",
      order: 3,
      type: "page",
    },
  ]
    .filter((x) => x.type === "page")
    .sort((a, b) => a.order - b.order);

  const myPath =
    typeof window === "undefined" || !window.document
      ? ""
      : document.location.pathname.replace("/", "");

  return (
    <MenuDiv>
      <li className={myPath === "" ? "on" : null}>
        <Link to="/">Home</Link>
      </li>
      {pageList.map((x, index) => {
        // console.log(x.slug, myPath, x.slug === myPath);
        return (
          <li key={index} className={myPath === x.slug ? "on" : null}>
            <Link to={`/${x.slug}`}>{x.shorttitle || x.title}</Link>
          </li>
        );
      })}
    </MenuDiv>
  );
};

export default Menu;
