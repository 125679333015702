import React from "react";
import { Link } from "gatsby";
import { FooterDiv, FooterImage } from "./../styledcomponents/layoutstyles";
import RingOnDeliImage from "./../images/remadelogo.svg";

const Footer = () => (
  <FooterDiv>
    <Link to={"/"}>
      <FooterImage src={RingOnDeliImage} />
    </Link>
    <p>
      © {new Date().getFullYear()} Eric Giroux and{" "}
      <a
        href="https://newsalembooks.com"
        target="_blank"
        rel="noopener noreferrer"
        style={{ color: "var(--white)" }}
      >
        New Salem Books
      </a>
      .{" "}
      <Link to={"/disclaimer"} style={{ color: "var(--white)" }}>
        Disclaimer.
      </Link>
    </p>
  </FooterDiv>
);

export default Footer;
