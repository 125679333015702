import React from "react";
import styled from "styled-components";
import useWindowSize from "../hooks/windowsize";

import image1 from "./../images/beefsirlointips.jpg";
import image2 from "./../images/chickenthighs.jpg";
import image3 from "./../images/codfillets.jpg";
import image4 from "./../images/cucumbers.jpg";
import image5 from "./../images/porkribs.jpg";
import image6 from "./../images/redonions.jpg";
import image7 from "./../images/americancheese.jpg";
import image8 from "./../images/turkeysub.jpg";
import image9 from "./../images/anjoupears.jpg";
import image10 from "./../images/honeycrispapples.jpg";
import image11 from "./../images/cornbread.jpg";
import image12 from "./../images/chickenpenne.jpg";
import image13 from "./../images/crabrangoon.jpg";
import image14 from "./../images/groundbeefpatties.jpg";
import image15 from "./../images/porkshoulder.jpg";
import image16 from "./../images/steelheadtrout.jpg";

// TODO: We could store the positions of each image and use it as the position to move from to the next one?

const BackdropDiv = styled.div`
  position: fixed;
  user-select: none;
  z-index: 1;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: var(--white);
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

const BackdropScrim = styled.div`
  position: fixed;
  user-select: none;
  z-index: 9999;
  left: -25%;
  right: -25%;
  top: 0;
  bottom: 0;
  display: flex;
  transform: skew(20deg);
  transition: 0.5s;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0.5) 50%,
    rgba(204, 32, 48, 0.8) 50%
  );
  background-size: 100px;
`;

const BounceImage = styled.img`
  opacity: 0.95;
  width: 400px;
  height: auto;
  position: absolute;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  /* transition: 0.5s; */
`;

const Backdrop = () => {
  const size = useWindowSize();
  const images = [];
  for (let i = 0; i < 6; i++) {
    images.push(
      image1,
      image2,
      image3,
      image4,
      image5,
      image6,
      image7,
      image8,
      image9,
      image10,
      image11,
      image12,
      image13,
      image14,
      image15,
      image16
    );
  }

  const imagesLength = images.length;

  const imageRef = React.useMemo(
    () => Array.from({ length: imagesLength }).map(() => React.createRef()),
    [imagesLength]
  );

  // for (let i = 0; i < images.length; i++) {
  // 	imageRef[i] = React.useRef(null);
  // }

  React.useEffect(() => {
    // console.log('Window size: ', size.width, size.height);
    imageRef.forEach((image) => {
      const mySize = image.current.getBoundingClientRect();
      image.current.style.top =
        Math.random() * (size.height + mySize.height) -
        mySize.height / 2 +
        "px";
      image.current.style.left =
        Math.random() * (size.width + mySize.width) - mySize.width / 2 + "px";
      image.current.style.transform = `rotate(${Math.random() * 360}deg)`;
    });
  });

  return (
    <BackdropDiv>
      {images.map((image, index) => {
        return (
          <BounceImage
            src={image}
            key={index}
            ref={imageRef[index]}
            style={{
              top: Math.random() * size.height,
              left: Math.random() * size.width,
            }}
          />
        );
      })}
      <BackdropScrim />
    </BackdropDiv>
  );
};

export default Backdrop;
