import styled from "styled-components";
import stars from "./../images/starswhite.svg";
import starsGold from "./../images/starsfull.svg";

export const FrontWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 2;
  display: flex;
  margin: var(--outsidePadding) 0 0 0;
  & > div {
    box-sizing: border-box;
    max-width: 880px;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    background-color: rgba(255, 255, 255, 1);
    background-color: var(--blue);
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.125);
    border-radius: var(--insidePadding);
    padding: var(--outsidePadding);
    background-image: url(${stars});
    background-size: 25%;
    margin-bottom: var(--outsidePadding);
  }
  @media screen and (max-width: 1023px) {
    margin: 0;
    & > div {
      margin: 0 auto;
      padding: var(--insidePadding);
      border-radius: 0;
    }
  }
  @media screen and (max-width: 768px) {
    & > div {
      padding: 0;
    }
  }
`;

export const MainDiv = styled.main`
  background-color: var(--white);
  padding: var(--outsidePadding);
  box-shadow: 0 0 10px rgba(255, 0, 0, 0.25);
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  border-radius: var(--insidePadding);
  &:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: var(--red);
    background-image: url(${starsGold});
    background-size: 10%;
  }
  & > div {
    z-index: 2;
    padding: var(--outsidePadding);
    background-color: rgba(255, 255, 255, 1);
    border-radius: var(--insidePadding);
    border: 1px solid var(--white);
    box-shadow: 0 0 10px var(--white);
    & > div {
      max-width: 768px;
      width: 100%;
      margin-left: auto;
      margin-right: auto;
    }
  }
  @media screen and (max-width: 1023px) {
    & > div {
      padding: var(--insidePadding);
    }
  }
  @media screen and (max-width: 767px) {
    border-radius: 0;
  }
`;

export const FooterDiv = styled.footer`
  margin-top: var(--outsidePadding);
  padding: var(--insidePadding);
  border-radius: var(--insidePadding);
  font-family: var(--uiFont);
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--white);
  user-select: none;
  & p {
    margin: 0 0 0 var(--insidePadding);
  }
  & a {
    color: var(--red);
    font-weight: bold;
  }
`;

export const FooterImage = styled.img`
  width: 64px;
  height: 64px;
  transition: 0.5s;
  transform: rotate(5deg);
  &:hover {
    width: 128px;
    height: 128px;
    transform: rotate(365deg);
  }
`;

export const PageText = styled.div`
  font-family: var(--uiFont);
  & div.interview {
    margin-left: var(--outsidePadding);
    @media screen and (max-width: 767px) {
      margin-left: 0;
    }
  }
  & p {
    font-size: 18px;
    line-height: 26px;
    margin: 0;
    &.starred {
      text-align: center;
      text-indent: 0 !important;
      font-style: italic;
      font-weight: bold;
      color: var(--blue);
      font-size: 24px;
      &:before {
        content: "★ ";
        color: var(--gold);
      }
      &:after {
        content: " ★";
        color: var(--gold);
      }
    }
    &.dropcap {
      &:first-line {
        font-variant-caps: small-caps;
        -moz-font-feature-settings: "smcp";
        -webkit-font-feature-settings: "smcp";
        font-feature-settings: "smcp";
        letter-spacing: 1.75px;
        color: var(--blue);
      }
      &:first-letter {
        float: left;
        font-size: 100px;
        line-height: 0.78;
        padding-top: 0;
        margin: 0;
        padding-right: 1px;
        color: var(--red);
        font-weight: bold;
      }
    }

    & a {
      font-weight: bold;
    }
    & + p {
      text-indent: 1em;
    }
  }
  & img {
    width: 100%;
    height: auto;
    position: relative;
  }
`;

export const PageH1 = styled.h1`
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: var(--red);
  font-size: 36px;
  line-height: 36px;
  margin-top: 0;
  margin-bottom: var(--outsidePadding);
  position: relative;
`;
export const PageH2 = styled.h2`
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1.5px;
  color: var(--red);
  margin: var(--outsidePadding) 0 0 0;
`;

export const PageWrapperDiv = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: var(--outsidePadding);
  & .gatsby-image-wrapper {
    margin: 1em 0;
  }
  & h2 {
    color: var(--red);
  }
  & > div {
    width: 100%;
  }
  & .contactform {
    & p {
      text-indent: 0 !important;
      margin-top: var(--outsidePadding);
      & label {
        display: flex;
        & span {
          flex: 1;
          font-weight: bold;
          color: var(--blue);
        }
        & input {
          flex: 2;
        }
        & textarea {
          flex: 2;
        }
      }
      &.checkbox {
        & label {
          display: inline-block;
          align-items: baseline;
          flex: 1;
          & input {
            position: relative;
            top: -2px;
          }
          & span {
            flex: 2;
          }
        }
      }
      &.button {
        text-align: right;
        & button,
        & input[type="submit"] {
          margin-left: auto;
          background-color: var(--red);
          color: white;
          font-weight: bold;
          transition: 0.5s;
          &:hover {
            background-color: var(--blue);
          }
        }
      }
    }
  }
`;
