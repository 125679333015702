/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react";
import PropTypes from "prop-types";
import { useStaticQuery, graphql } from "gatsby";
import { FrontWrapper, MainDiv } from "./../styledcomponents/layoutstyles";
import SEO from "./../components/seo";

import Backdrop from "./backdrop";
import Menu from "./menu";
import Header from "./header";
import Footer from "./footer";

const Layout = ({ children, title, bookData }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `);

  return (
    <>
      <Backdrop />
      <FrontWrapper>
        <div>
          <Header siteTitle={data.site.siteMetadata.title} />
          <Menu />
          <div>
            <MainDiv>
              <div>{children}</div>
            </MainDiv>
            <Footer />
          </div>
        </div>
      </FrontWrapper>
      <SEO title={title || data.site.siteMetadata.title} bookData={bookData} />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
